import React, { FC, MouseEventHandler } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import styled from "styled-components";
import mapData from "../assets/map-data.json";
import { Layout } from "../components/layout";

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
`;

const StyledComposableMap = styled(ComposableMap)`
  width: 100%;
  height: auto;
`;

// This is the list of the countries visited, identified using their ISO a3 codes
// When adding a new country just get the country code from this list
// https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes and add it to the
// list - might try and figure out a cleaner way to do this in the future
const countriesVisited = [
  "AUT",
  "AZE",
  "BIH",
  "CAN",
  "CHE",
  "CHN",
  "CZE",
  "DEU",
  "ESP",
  "FRA",
  "GEO",
  "GIB",
  "GRC",
  "HKG",
  "HRV",
  "HUN",
  "ITA",
  "JPN",
  "KAZ",
  "KGZ",
  "MAR",
  "SVK",
  "TUR",
  "USA",
  "UZB",
  "PRT",
  "CHL",
  "ARG",
  "URY",
  "GBR",
  "NLD",
  "BEL",
  "KOR",
  "THA",
  "TWN",
  "LIE",
  "ISL",
  "MEX",
];

// Might be useful at some point if I add a country click function or something
const handleCountryClick: MouseEventHandler<SVGPathElement> = (event) => {
  //console.log(event)
  //event.preventDefault();
  //const data = new FormData(event.target);
};

const fillVisited = (country: string) => {
  if (countriesVisited.includes(country)) {
    return "#FFD700";
  } else {
    return "#ECEFF1";
  }
};

// This page is for a map of all the countries I've been too
const TravelMapPage: FC = () => (
  <Layout>
    <h3>Travel Map</h3>
    <p>
      I've always loved traveling, and have been lucky to have the opportunity
      to travel overseas a bit! The map below highlights all the countries I've
      been to - might try and include a way to highlight the place I'm at in the
      future.
    </p>
    <p>
      I'd also like to build it out so that you can click the countries and view
      some photos for that country or maybe a little blurb on some cool things
      I've done in different parts of the world. Also one more note - I've never
      actually been to French Guiana (just standard non-guiana'd France), but
      the map library I used doesn't distinguish between them. Guess I'll have
      to make a trip to French Guiana so the map is actually accurate (that
      seems like the best way to fix this bug).
    </p>
    <p>
      Number of countries/
      <a href="https://en.wikipedia.org/wiki/Gibraltar">
        territories
      </a> visited: {countriesVisited.length}
    </p>
    <StyledWrapper>
      <StyledComposableMap
        projectionConfig={{
          scale: 180,
        }}
        width={980}
      >
        <Geographies geography={mapData}>
          {({ geographies }) =>
            geographies.map((geography, i) => (
              <Geography
                key={i}
                geography={geography}
                onClick={handleCountryClick}
                style={{
                  default: {
                    fill: fillVisited(geography.properties.iso_a3),
                    stroke: "#607D8B",
                    strokeWidth: 0.75,
                    outline: "none",
                  },
                  hover: {
                    fill: fillVisited(geography.properties.iso_a3),
                    stroke: "#607D8B",
                    strokeWidth: 0.75,
                    outline: "none",
                  },
                  pressed: {
                    fill: fillVisited(geography.properties.iso_a3),
                    stroke: "#607D8B",
                    strokeWidth: 0.75,
                    outline: "none",
                  },
                }}
              />
            ))
          }
        </Geographies>
      </StyledComposableMap>
    </StyledWrapper>
  </Layout>
);

export default TravelMapPage;
